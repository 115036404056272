"use client";

import type {
  ReactNode,
  ElementType,
  ReactElement,
  MutableRefObject,
  ForwardedRef,
  JSXElementConstructor,
  ComponentType,
} from "react";
import type {
  PopoverProps,
  PopoverButtonProps,
  PopoverPanelProps,
  PopoverGroupProps,
  PopoverOverlayProps,
} from "@headlessui/react";
import type { TransitionRootProps } from "./Transition";
import React, {
  forwardRef,
  useRef,
  useEffect,
  useState,
  useCallback,
  Children,
  isValidElement,
  Fragment,
  cloneElement,
} from "react";
import { useHover, useClickAway, useTimeoutFn } from "react-use";
import { Popover, Transition as HuiTransition } from "@headlessui/react";
import Transition from "./Transition";

type TimedPopoverProps<TTag extends ElementType> = Omit<
  PopoverProps<TTag>,
  "children"
> & {
  children: ReactNode;
  openOnHover?: boolean;
  popoverTimeout?: number;
};

const symbolReactLazy = Symbol.for("react.lazy");

function modifyTransition(
  child: ReactElement<TransitionRootProps<ElementType>>,
  open: boolean,
  key: string
) {
  // let modifiedChild = child as React.ReactElement<
  //   TransitionRootProps<ElementType>
  // >;
  // modifying the Transition component to show based on the open state.
  // modifiedChild = cloneElement(modifiedChild, {
  //   show: open || isOpen,
  // });
  const modifiedChild = (
    <Transition {...child.props} key={child.key || key} show={open} />
  );
  return modifiedChild;
}

function mapTimedPopoverChildren(
  children: ReactNode,
  open: boolean,
  iteration: number = 1
): ReactNode {
  return Children.map(
    children,
    (node: ReactNode, index: number): ReactElement => {
      // loop through all children and check if it is a Transition component.
      if (isValidElement(node)) {
        const child = node as ReactElement<{ children?: ReactNode }>;
        const childType = child.type as typeof child.type & {
          readonly $$typeof?: symbol;
          readonly _payload?: {
            reason?: string;
            status?: string;
            value: JSXElementConstructor<unknown> | ComponentType<unknown>;
            // value: Component | ExoticComponent;
          };
        };
        // console.log(
        //   iteration,
        //   index,
        //   "\nchildType",
        //   childType,
        //   "\nchild",
        //   child
        // );
        if (
          typeof childType !== "string" &&
          Object.hasOwnProperty.call(childType, "$$typeof") &&
          childType["$$typeof"] === symbolReactLazy &&
          Object.hasOwnProperty.call(childType, "_payload") &&
          typeof childType["_payload"] !== "undefined"
        ) {
          // this section exists for lazy loaded react components
          // which could be manually added using react.lazy or by using client components in server components
          const payload = childType["_payload"];
          const pvalue = payload.value;
          if (pvalue === Transition || pvalue === HuiTransition) {
            // lazy loaded transition components
            const modifiedChild = modifyTransition(
              child as ReactElement<TransitionRootProps<ElementType>>,
              open,
              `${iteration}-${index} transition`
            );
            // console.log("lazy modifiedTransition", modifiedChild);
            return modifiedChild;
          } else if (
            pvalue === TimedPopoverButton ||
            pvalue === TimedPopoverOverlay ||
            pvalue === TimedPopoverPanel ||
            pvalue === Popover.Button ||
            pvalue === Popover.Overlay ||
            pvalue === Popover.Panel
          ) {
            // lazy loaded popover components
            // console.log("lazy popover components", pvalue);
            return cloneElement(child, {
              key: child.key || `${iteration}-${index} popover`,
            });
          }
        }

        if (childType === Transition || childType === HuiTransition) {
          // transition components
          const modifiedChild = modifyTransition(
            child as ReactElement<TransitionRootProps<ElementType>>,
            open,
            `${iteration}-${index} transition`
          );
          // console.log("modifiedTransition", modifiedChild);
          return modifiedChild;
        } else if (
          childType === TimedPopoverButton ||
          childType === TimedPopoverOverlay ||
          childType === TimedPopoverPanel ||
          childType === Popover.Button ||
          childType === Popover.Overlay ||
          childType === Popover.Panel
        ) {
          // popover components
          // console.log("popover components", childType);
          return cloneElement(child, {
            key: child.key || `${iteration}-${index} popover`,
          });
        } else {
          // return other react elements cloned with a key
          const subChildren = mapTimedPopoverChildren(
            child.props.children,
            open,
            iteration + 1
          );
          // console.log("subChildren", subChildren);
          return cloneElement(
            child,
            { key: child.key || `${iteration}-${index} other` },
            subChildren
          );
        }
      } else {
        // return other nodes in a fragment with key.
        return <Fragment key={node?.toString()}>{node}</Fragment>;
      }
    }
  );
}

const TimedPopoverFn = forwardRef(function <TTag extends ElementType = "div">(
  {
    children,
    openOnHover = true,
    popoverTimeout,
    ...props
  }: TimedPopoverProps<TTag>,
  ref?: ForwardedRef<HTMLDivElement>
) {
  const [isOpen, setIsOpen] = useState(false);
  const openRef = useRef<boolean>(false);
  const closeRef = useRef<
    (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  >(() => {});

  const refClickAway = useRef<HTMLDivElement | null>(null);
  const callbackRef = useCallback(
    (node: HTMLDivElement | null) => {
      refClickAway.current = node;
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [ref]
  );

  const popover = (
    <Popover {...props} ref={callbackRef}>
      {({ open, close }) => {
        openRef.current = open;
        closeRef.current = close;
        return <>{mapTimedPopoverChildren(children, open || isOpen)}</>;
      }}
    </Popover>
  );
  const [hoverable, isHovering] = useHover(popover);
  const timeout: number =
    typeof popoverTimeout === "number" ? popoverTimeout : 500;
  const closeCb = useCallback(() => {
    setIsOpen(false);
    closeRef.current();
  }, [setIsOpen]);
  const [isClosed, cancelClose, resetClose] = useTimeoutFn(closeCb, timeout);
  cancelClose();

  useEffect(() => {
    if (isHovering) {
      if (openOnHover) {
        setIsOpen(true);
      }
      if (isClosed() !== null) {
        cancelClose();
      }
    } else {
      if (timeout <= 0) {
        cancelClose();
      } else if (!openRef.current && !isOpen) {
        cancelClose();
      } else if (!isClosed()) {
        resetClose();
      }
    }
    return cancelClose;
  }, [
    openOnHover,
    isOpen,
    timeout,
    isClosed,
    cancelClose,
    resetClose,
    isHovering,
  ]);
  const clickAwayCb = useCallback(() => {
    if (!isHovering && (openRef.current || isOpen)) {
      closeCb();
    }
  }, [isHovering, isOpen, closeCb]);

  useEffect(() => {
    callbackRef(refClickAway.current);
  }, [ref, callbackRef]);
  useClickAway(refClickAway, clickAwayCb);

  return hoverable;
});

TimedPopoverFn.displayName = "TimedPopover";

export const TimedPopoverGroup = forwardRef(function <
  TTag extends ElementType = "div",
>(props: PopoverGroupProps<TTag>, ref?: ForwardedRef<HTMLDivElement>) {
  return <Popover.Group {...props} ref={ref} />;
});

TimedPopoverGroup.displayName = "TimedPopoverGroup";

export const TimedPopoverButton = forwardRef(function <
  TTag extends ElementType = "button",
>(
  {
    // setButtonHovering,
    ...props
  }: PopoverButtonProps<TTag> & {
    // setButtonHovering: (isHovering: boolean) => void;
    disabled?: boolean;
  },
  ref?: ForwardedRef<HTMLButtonElement>
) {
  const button = <Popover.Button {...props} ref={ref} />;
  // const [hoverable, isHovering] = useHover(button);

  // useEffect(() => {
  //   setButtonHovering(isHovering);
  // }, [setButtonHovering, isHovering]);
  // return hoverable;
  return button;
});

TimedPopoverButton.displayName = "TimedPopoverButton";

export const TimedPopoverOverlay = forwardRef(function <
  TTag extends ElementType = "div",
>(
  props: PopoverOverlayProps<TTag> & {
    open: boolean;
  },
  ref?: ForwardedRef<HTMLDivElement>
) {
  return <Popover.Overlay {...props} ref={ref} />;
});

TimedPopoverOverlay.displayName = "TimedPopoverOverlay";

export const TimedPopoverPanel = forwardRef(function <
  TTag extends ElementType = "div",
>(
  {
    // setPanelHovering,
    ...props
  }: PopoverPanelProps<TTag> & {
    // setPanelHovering: (isHovering: boolean) => void;
    focus?: boolean;
  },
  ref?: ForwardedRef<HTMLDivElement>
) {
  const panel = <Popover.Panel {...props} ref={ref} />;
  // const [hoverable, isHovering] = useHover(panel);

  // useEffect(() => {
  //   setPanelHovering(isHovering);
  // }, [setPanelHovering, isHovering]);
  // return hoverable;
  return panel;
});

TimedPopoverPanel.displayName = "TimedPopoverPanel";

// export default Object.assign(TimedPopoverFn, {
//   Group: TimedPopoverGroup,
//   Button: TimedPopoverButton,
//   Overlay: TimedPopoverOverlay,
//   Panel: TimedPopoverPanel,
// });

type TTimedPopover = typeof TimedPopoverFn & {
  Group: typeof TimedPopoverGroup;
  Button: typeof TimedPopoverButton;
  Overlay: typeof TimedPopoverOverlay;
  Panel: typeof TimedPopoverPanel;
};

const TimedPopover = TimedPopoverFn as TTimedPopover;
TimedPopover.Group = TimedPopoverGroup;
TimedPopover.Button = TimedPopoverButton;
TimedPopover.Overlay = TimedPopoverOverlay;
TimedPopover.Panel = TimedPopoverPanel;

export default TimedPopover;
