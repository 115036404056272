"use client";

import type { ElementType, ForwardedRef } from "react";
import type { TransitionChildProps as TransitionProps } from "@headlessui/react";
import { forwardRef } from "react";
import { Transition as Tran } from "@headlessui/react";

export type TransitionChildProps<TTag extends ElementType = "div"> = {
  [k in keyof TransitionProps<TTag>]: TransitionProps<TTag>[k];
} & {
  appear?: boolean;
};
export type TransitionRootProps<TTag extends ElementType = "div"> =
  TransitionChildProps<TTag> & {
    show?: boolean;
    appear?: boolean;
  };

export const TransitionRoot = forwardRef(function <
  TTag extends ElementType = "div",
>(props: TransitionRootProps<TTag>, forwardedRef?: ForwardedRef<HTMLElement>) {
  return <Tran {...props} ref={forwardedRef} />;
});

TransitionRoot.displayName = "Transition";

export const TransitionChild = forwardRef(function <
  TTag extends ElementType = "div",
>(props: TransitionChildProps<TTag>, forwardedRef?: ForwardedRef<HTMLElement>) {
  return <Tran.Child {...props} ref={forwardedRef} />;
});

TransitionChild.displayName = "Transition.Child";

const Transition = TransitionRoot as typeof TransitionRoot & {
  Child: typeof TransitionChild;
  Root: typeof TransitionRoot;
};
Transition.Child = TransitionChild;
Transition.Root = TransitionRoot;

export default Transition;
