import type { MainMenu, SubMenu, CtaMenu } from "./types";
import {
  EnvelopeIcon,
  UserGroupIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";

export const companyCta: CtaMenu = [
  {
    name: "Contact Us",
    href: "mailto:hello@gameanglostudios.com",
    icon: EnvelopeIcon,
  },
];
export const companyMenu: SubMenu = [
  {
    name: "About Us",
    description: "Learn more about us",
    href: "/company/about",
    icon: UserGroupIcon,
  },
  {
    name: "Social Media",
    description: "Connect with us on social media",
    href: "/company/social-media",
    icon: GlobeAltIcon,
  },
];

export const mainMenu: MainMenu = [
  { name: "Home", href: "/" },
  { name: "Games", href: "/games" },
  { name: "Company", href: "#", submenu: companyMenu, cta: companyCta },
  { name: "News", href: "/news" },
];
